import { NextPageWithLayout } from 'next';
import React from 'react';

import { PageLayout } from '../components/common/layout';
import { CustomLink } from '../components/ui/link';
import Image from "next/image";

const openSeaUrl = 'https://opensea.io/collection/aotlegacy';

const IndexPage: NextPageWithLayout = () => {
  return (
    <div>
      <Image src="/assets/teaser_visual.jpg" alt="background" className="opacity-30" fill style={{objectFit: 'cover'}} />
      <div className="relative flex flex-col text-center gap-8 z-1">
        <p className="font-bold">
          As of December 27, 2023 (JST), Attack on Titan: Legacy has fully transitioned to a decentralized marketplace.
        </p>
        <div className="text-sm underline hover:text-gray-300 text-lg font-bold">
          <CustomLink href={openSeaUrl}>OpenSea</CustomLink>
        </div>
        <p className="pt-8">
          For more information about the service, please reach out to Customer Support by clicking on the button on the bottom-right of this page.
        </p>

        <p>
          【Service Notice】<br />
          <strong>From Dec. 26, 2023 to Jan. 5, 2024</strong>, our Customer Support will be closed due to year-end holidays.<br />
          Responses to inquiries may be delayed after we re-open.<br />
          Apologies for any inconvenience, and thank you for your patience.
        </p>
      </div>
    </div>
  );
};

IndexPage.getLayout = (Component) => {
  return <PageLayout>{Component}</PageLayout>;
};

export default IndexPage;
